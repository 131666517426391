import FriendLinks from '../FriendLinks'
import React from 'react'
import Section from '../Section/Section'
import fr from '../../locales/fr'

interface SectionFriendsProps {
  friendLinks: readonly GatsbyTypes.FriendLinkFragment[]
}
const classes = /** class={ */ {
  description: 'mx-auto italic font-light text-center mb-12 md:mb-20 lg:mb-24 max-w-5xl',
} /** } */

const SectionFriends: React.FunctionComponent<SectionFriendsProps> = ({ friendLinks }) => (
  <>
    <Section title={fr.friends} onlyOne>
      <p className={classes.description}>{fr.friendsDescription}</p>
      <FriendLinks friendsLinks={friendLinks} />
    </Section>
  </>
)

export default SectionFriends
