import React from 'react'
import fr from '../../locales/fr'
import { graphql } from 'gatsby'

interface FriendLinksProps {
  friendsLinks: readonly GatsbyTypes.FriendLinkFragment[]
}

const classes = /** class={ */ {
  list: 'list-none max-w-5xl mx-auto',
  friend: 'pt-6',
  title: 'text-xl md:text-2xl lg:text-3xl',
  excerpt: 'text-gray-300 my-4',
  link: 'text-primary hover:underline text-sm md:text-base',
} /** } */

const FriendLinks: React.FunctionComponent<FriendLinksProps> = ({ friendsLinks }) => {
  return (
    <ol className={classes.list}>
      {friendsLinks.map(friendLink => {
        return (
          <li key={friendLink.id}>
            <div className={classes.friend}>
              <header>
                <h2 className={classes.title}>{friendLink.title}</h2>
              </header>
              <section className={classes.excerpt}>{friendLink.linkAcf?.description}</section>
              <a
                href={friendLink.linkAcf?.link}
                className={classes.link}
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                {fr.learnMore}
              </a>
            </div>
          </li>
        )
      })}
    </ol>
  )
}

export default FriendLinks

export const FriendLinkFragment = graphql`
  fragment FriendLink on WpLink {
    id
    title
    linkAcf: acf {
      description
      link
    }
  }
`
