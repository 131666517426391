import Layout from '../components/Layout'
import React from 'react'
import SectionFriends from '../components/SectionFriends'
import Seo from '../components/Seo'
import fr from '../locales/fr'
import { graphql } from 'gatsby'

interface FriendsLinksPageProps {
  data: GatsbyTypes.FriendsLinkPageQuery
}
const FriendsLinksPage: React.FunctionComponent<FriendsLinksPageProps> = ({ data }) => (
  <Layout>
    <Seo title={fr.friends} description={fr.friendsDescription} />
    <SectionFriends friendLinks={data.allWpLink.nodes} />
  </Layout>
)

export default FriendsLinksPage

export const pageQuery = graphql`
  query FriendsLinkPage {
    allWpLink {
      nodes {
        ...FriendLink
      }
    }
  }
`
